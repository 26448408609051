import { required, minLength, maxLength, alphaNum, email, numeric } from "vuelidate/lib/validators";

function getValidations() {
    return {
        form: {
            websiteTitle: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(50),
            },
            websiteSlogan: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(250),
            },
            seoDescription: {
                required,
                minLength: minLength(1),
            },
            email: {
                email,
                required,
                minLength: minLength(5),
                maxLength: maxLength(50),
            },
            telephone: {
                required,
            },
            adEmail: {
                email,
                minLength: minLength(5),
                maxLength: maxLength(50),
            },
            adPhone: {
            },
            fax: {
            },
            address: {
                maxLength: maxLength(250),
            },
            pageRefreshPeriod: {
                required,
                minValue: 0,
                maxValue: 3600,
                numeric,

            }, insidePageRefreshPeriod: {
                required,
                minValue: 0,
                maxValue: 3600,
                numeric,

            }, liveVideoLink: {
                required,
                minLength: minLength(1),
                maxLength: maxLength(250),

            },
            facebook: {
                required,
                minLength: minLength(1),
                maxLength: maxLength(250),

            },
            instagram: {
                required,
                minLength: minLength(1),
                maxLength: maxLength(250),

            },
            twitter: {
                required,
                minLength: minLength(1),
                maxLength: maxLength(250),

            },
            youtube: {
                required,
                minLength: minLength(1),
                maxLength: maxLength(250),
            },
            whatsapp: {
                minLength: minLength(0),
                maxLength: maxLength(250),
            },
            magazineUrl: {}
        },
    }
}

export default { getValidations };