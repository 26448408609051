<template>
  <div class="animated fadeIn">
    <PhotoEditorModal
      :modalTitle="$t('edit')"
      :show="editModal"
      @hide="editModal = false"
      :fileModel="editModel"
      :validHeight="editHeight"
      :validWidth="editWidth"
      :cropIds="[]"
      :activeMenu="[
        'flip',
        'crop',
        'resize',
        'draw',
        'shape',
        'icon',
        'text',
        'mask',
        'filter',
      ]"
      @onSave="(base64) => saveAndUpload(base64)"
    ></PhotoEditorModal>

    <b-form @submit.stop.prevent="onSubmit">
      <b-row>
        <b-col sm="12">
          <b-card>
            <div slot="header">
              <strong>{{ $t("updateOptions") }}</strong>
            </div>

            <b-row>
              <b-col sm="6">
                <b-col sm="12">
                  <ValidationableTextBox
                    prename="option"
                    id="websiteTitle"
                    icon="fa fa-font"
                    v-model="$v.form.websiteTitle.$model"
                    :form="$v.form.websiteTitle"
                  />
                </b-col>

                <b-col sm="12">
                  <ValidationableTextBox
                    prename="option"
                    id="websiteSlogan"
                    icon="fa fa-font"
                    v-model="$v.form.websiteSlogan.$model"
                    :form="$v.form.websiteSlogan"
                  />
                </b-col>
                <b-col sm="12">
                  <ValidationableTextBox
                    prename="option"
                    icon="fa fa-envelope"
                    id="email"
                    v-model="$v.form.email.$model"
                    :form="$v.form.email"
                  />
                </b-col>

                <b-col sm="12">
                  <ValidationableTextBox
                    prename="option"
                    id="telephone"
                    icon="fa fa-phone"
                    v-model="$v.form.telephone.$model"
                    :form="$v.form.telephone"
                  />
                </b-col>

                <b-col sm="12">
                  <ValidationableTextBox
                    prename="option"
                    icon="fa fa-envelope"
                    id="adEmail"
                    v-model="$v.form.adEmail.$model"
                    :form="$v.form.adEmail"
                  />
                </b-col>

                <b-col sm="12">
                  <ValidationableTextBox
                    prename="option"
                    id="adPhone"
                    icon="fa fa-phone"
                    v-model="$v.form.adPhone.$model"
                    :form="$v.form.adPhone"
                  />
                </b-col>

                <b-col sm="12">
                  <ValidationableTextBox
                    prename="option"
                    id="fax"
                    icon="fa fa-fax"
                    v-model="$v.form.fax.$model"
                    :form="$v.form.fax"
                  />
                </b-col>
                <b-col sm="12">
                  <ValidationableTextBox
                    prename="option"
                    id="liveVideoLink"
                    icon="fa fa-link"
                    v-model="$v.form.liveVideoLink.$model"
                    :form="$v.form.liveVideoLink"
                  />
                </b-col>
                <b-col sm="12">
                  <CheckBox
                    v-model="option.liveVideoActive"
                    :defaultValue="option.liveVideoActive"
                    :label="$t('liveVideoActive')"
                  ></CheckBox>
                </b-col>
                <b-col sm="12">
                  <CheckBox
                    v-model="option.lastMinuteActive"
                    :defaultValue="option.lastMinuteActive"
                    :label="$t('lastMinuteActive')"
                  ></CheckBox>
                </b-col>
                <b-col sm="12">
                  <ValidationableTextBox
                    prename="option"
                    id="twitter"
                    icon="fa fa-twitter"
                    iconColor="text-primary"
                    v-model="$v.form.twitter.$model"
                    :form="$v.form.twitter"
                  />
                </b-col>
                <b-col sm="12">
                  <ValidationableTextBox
                    prename="option"
                    id="instagram"
                    icon="fa fa-instagram"
                    iconColor="text-danger"
                    v-model="$v.form.instagram.$model"
                    :form="$v.form.instagram"
                  />
                </b-col>
                <b-col sm="12">
                  <ValidationableTextBox
                    prename="option"
                    id="whatsapp"
                    icon="fa fa-whatsapp"
                    iconColor="text-success"
                    v-model="$v.form.whatsapp.$model"
                    :form="$v.form.whatsapp"
                  />
                </b-col>
              </b-col>

              <b-col sm="6">
                <b-col sm="12">
                  <label for="seoKeywords">{{ $t("seoKeywords") }}</label>
                  <b-form-tags
                    input-id="seoKeywords"
                    v-model="keyWords"
                    separator=",;"
                    :placeholder="$t('seoKeywords')"
                    add-on-enter
                    :addButtonText="$t('add')"
                  ></b-form-tags>
                </b-col>

                <b-col sm="12">
                  <ValidationableTextArea
                    prename="option"
                    id="seoDescription"
                    :rows="6"
                    v-model="$v.form.seoDescription.$model"
                    :form="$v.form.seoDescription"
                  />
                </b-col>
                <b-col sm="12">
                  <ValidationableTextArea
                    prename="option"
                    id="address"
                    v-model="$v.form.address.$model"
                    :form="$v.form.address"
                  />
                </b-col>

                <b-col sm="12" style="padding-top: 7px">
                  <ValidationableTextBox
                    prename="option"
                    id="pageRefreshPeriod"
                    icon="fa fa-refresh"
                    type="number"
                    v-model="$v.form.pageRefreshPeriod.$model"
                    :form="$v.form.pageRefreshPeriod"
                  />
                </b-col>
                <b-col sm="12" style="padding-top: 7px">
                  <ValidationableTextBox
                    prename="option"
                    id="insidePageRefreshPeriod"
                    icon="fa fa-refresh"
                    type="number"
                    v-model="$v.form.insidePageRefreshPeriod.$model"
                    :form="$v.form.insidePageRefreshPeriod"
                  />
                </b-col>
                <b-col sm="12">
                  <ValidationableTextBox
                    prename="option"
                    id="facebook"
                    icon="fa fa-facebook"
                    iconColor="text-info"
                    v-model="$v.form.facebook.$model"
                    :form="$v.form.facebook"
                  />
                </b-col>
                <b-col sm="12">
                  <ValidationableTextBox
                    prename="option"
                    id="youtube"
                    icon="fa fa-youtube-play"
                    iconColor="text-danger"
                    v-model="$v.form.youtube.$model"
                    :form="$v.form.youtube"
                  />
                </b-col>
              </b-col>
            </b-row>

            <b-row>
              <div class="card-block text-center text-white">
                <label>{{ $t("magazine") }}</label>
                <div class="m-b-25">
                  <b-img
                    :src="getMagazineSource()"
                    class="magazine-img"
                    alt="Magazine-Image"
                    thumbnail
                    rounded
                    @click="editMagazine()"
                  />
                  <b-progress
                    :max="100"
                    height="1rem"
                    animated
                    style="margin: 2px"
                    v-if="form.magazine.uploadPercentage > 0"
                  >
                    <b-progress-bar :value="form.magazine.uploadPercentage" variant="primary">
                      <span>
                        {{ $t("loading") }}:
                        <strong>
                          {{
                          form.magazine.uploadPercentage.toFixed(0)
                          }}%
                        </strong>
                      </span>
                    </b-progress-bar>
                  </b-progress>
                </div>
                <h6 class="f-w-600">
                  <input
                    type="file"
                    accept="image/*"
                    class="d-none"
                    ref="uploadFile"
                    v-on:change="() => fileLoaded()"
                  />
                  <BrandButton
                    :text="$t('browse')"
                    buttonIcon="fa fa-folder-open"
                    buttonVariant="primary"
                    buttonClass="mr-1 btn-brand nowrap"
                    @click="browse()"
                  ></BrandButton>
                </h6>

                 <b-col sm="12">
                  <ValidationableTextBox
                    prename="option"
                    id="url"
                    icon="fa fa-font"
                    v-model="$v.form.magazineUrl.$model"
                    :form="$v.form.magazineUrl"
                  />
                </b-col>
              </div>
            </b-row>

            <b-row>
              <b-col sm="12" class="text-right">
                <div class="form-actions">
                  <BrandButton
                    buttonVariant="success"
                    buttonIcon="fa fa-save"
                    @click="save"
                    :text="$t('save')"
                    v-if="$auth.hasAuth($options, 'save')"
                  />

                  <BrandButton
                    buttonVariant="danger"
                    buttonIcon="fa fa-undo"
                    @click="back"
                    :text="$t('back')"
                  />
                </div>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>
<script>
import http from "../../shared/http";
import optionValidator from "../../validations/option-validator";
import toast from "../../shared/toast-helper";
import ValidationableTextBox from "../../widgets/form/ValidationableTextBox";
import ValidationableTextArea from "../../widgets/form/ValidationableTextArea";
import BrandButton from "../../widgets/form/CustomBrandButton";
import CheckBox from "../../widgets/form/CustomCheckBox";
import { validationMixin } from "vuelidate";
import generatorsHelper from "../../shared/utils/generators-helper";
import generalHelper from "../../shared/utils/general-helper";
import toastHelper from "../../shared/toast-helper";

import PhotoEditorModal from "../../widgets/news/PhotoEditorModal";
export default {
  name: "Options",
  components: {
    ValidationableTextBox,
    ValidationableTextArea,
    BrandButton,
    CheckBox,
    PhotoEditorModal
  },
  mixins: [validationMixin],
  validations: optionValidator.getValidations(),
  data() {
    return {
      editModal: false,
      editModel: {},
      editHeight: 500,
      editWidth: 400,
      keyWords: [],
      form: {
        websiteTitle: "",
        websiteSlogan: "",
        seoDescription: "",
        facebook: "",
        twitter: "",
        youtube: "",
        instagram: "",
        whatsapp: "",
        magazineUrl: "",
        magazineFileId: "",
        email: "",
        telephone: "",
        adEmail: "",
        adPhone: "",
        fax: "",
        address: "",
        liveVideoLink: "",
        pageRefreshPeriod: 0,
        insidePageRefreshPeriod: 0,
        magazine: {
          localFile: null,
          onlineFile: null,
          uploadPercentage: 0
        }
      },
      option: {
        id: 0,
        websiteTitle: "",
        websiteSlogan: "",
        facebook: "",
        twitter: "",
        youtube: "",
        instagram: "",
        whatsapp: "",
        magazineUrl: "",
        magazineFileId: "",
        seoKeywords: "",
        seoDescription: "",
        email: "",
        telephone: "",
        adEmail: "",
        adPhone: "",
        fax: "",
        address: "",
        liveVideoLink: "",
        liveVideoActive: false,
        pageRefreshPeriod: 0,
        insidePageRefreshPeriod: 0,
        lastMinuteActive: true
      }
    };
  },
  watch: {
    keyWords() {
      let keys = "";
      if (this.keyWords != null) {
        this.keyWords.forEach(element => {
          keys = keys + "," + element;
        });
        this.option.seoKeywords = keys.substr(1);
      }
    }
  },
  methods: {
    calculateImageSize() {
      if (this.form === undefined) return;
      let img = new Image();
      img.onload = () => {
        this.editHeight = img.height || 500;
        this.editWidth = img.width || 350;
      };
      img.src =
        this.form.magazine.localFile == null
          ? this.form.magazine.onlineFile.fileName
          : this.form.magazine.localFile;
    },
    saveAndUpload(base64) {
      this.editModal = false;
      this.remove(false);
      var block = base64.split(";");
      var contentType = block[0].split(":")[1];
      var realData = block[1].split(",")[1];
      var blob = generatorsHelper.b64toBlob(realData, contentType);
      this.form.magazine.localFile = base64;
      this.startUpload(null, blob);
    },
    browse() {
      this.$refs.uploadFile.click();
    },
    fileLoaded() {
      let file = this.$refs.uploadFile.files[0];
      if (generalHelper.isImageFile(file)) {
        this.form.magazine.uploadPercentage = 1;
        this.form.magazine.localFile = URL.createObjectURL(file);
        if (this.form.magazine.onlineFile != null) {
          this.remove(false);
        }
        this.calculateImageSize();
        this.startUpload(file);
      } else {
        toastHelper.error(this.$t("fileIsNotValid"));
      }
    },
    startUpload(file, blob = null) {
      let formData = new FormData();
      if (blob != null) {
        formData.append("file", blob, "image.jpg");
      } else {
        formData.append("file", file);
      }
      let self = this;
      http
        .post("/Files/upload", formData, {
          onUploadProgress: function(progressEvent) {
            this.form.magazine.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this)
        })
        .then(response => {
          this.form.magazine.onlineFile = response.data;
          this.form.magazine.uploadPercentage = 0;
        })
        .catch(e => {
          if (blob == null) {
            self.remove();
          }
          if (!e.response.data.success) {
            toastHelper.error(e.response.data.message);
          }
        });
    },
    remove(withMessage = true) {
      if (this.form.magazine.onlineFile != null) {
        let model = {
          fileId: this.form.magazine.onlineFile.id
        };
        http
          .post(
            `Files/deletefilebyid?fileId=${this.form.magazine.onlineFile.id}`
          )
          .then(response => {
            if (withMessage) toastHelper.success(response.message);
          });
      }
    },
    editMagazine() {
      if (this.form.magazine.onlineFile != null) {
        this.editModel = this.form.magazine;
        this.editModal = true;
      } else {
        toastHelper.error(this.$t("notUploadedYet"));
      }
    },
    getMagazineSource() {
      if (
        this.form.magazine.localFile == null &&
        this.form.magazine.onlineFile == null
      ) {
        return "/img/user.png";
      } else if (this.form.magazine.localFile != null) {
        return this.form.magazine.localFile;
      } else {
        return this.form.magazine.onlineFile.fileName;
      }
    },
    save() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) return;
      let model = { ...this.option, ...this.form, id: 1 };
      if (this.form.magazine && this.form.magazine.onlineFile) {
        model.magazineFileId = this.form.magazine.onlineFile.id;
      }
      model.pageRefreshPeriod = parseInt(model.pageRefreshPeriod);
      model.insidePageRefreshPeriod = parseInt(model.insidePageRefreshPeriod);
      let path = "options/update";
      http.post(path, model).then(result => {
        if (result && result.success) {
          toast.success(result.message);
          this.$router.push({ path: "/dashboard" });
        }
      });
    },
    back() {
      this.$router.back();
    }
  },
  created() {
    let path = `options/get`;
    http.get(path).then(option => {
      if (option) {
        this.option = option;
        this.form.websiteTitle = option.websiteTitle ? option.websiteTitle : "";
        this.form.websiteSlogan = option.websiteSlogan
          ? option.websiteSlogan
          : "";

        this.form.seoDescription = option.seoDescription
          ? option.seoDescription
          : "";

        this.form.email = option.email ? option.email : "";

        this.form.telephone = option.telephone ? option.telephone : "";

        this.form.adEmail = option.adEmail ? option.adEmail : "";

        this.form.adPhone = option.adPhone ? option.adPhone : "";

        this.form.fax = option.fax ? option.fax : "";

        this.form.address = option.address ? option.address : option.address;
        this.form.pageRefreshPeriod = option.pageRefreshPeriod;
        this.form.insidePageRefreshPeriod = option.insidePageRefreshPeriod;
        this.form.liveVideoLink = option.liveVideoLink;
        this.form.instagram = option.instagram;
        this.form.twitter = option.twitter;
        this.form.youtube = option.youtube;
        this.form.whatsapp = option.whatsapp;
        this.form.magazineUrl = option.magazineUrl;
        this.form.magazineFileId = option.magazineFileId;

        this.form.magazine = {
          localFile: null,
          onlineFile: option.magazineFile,
          uploadPercentage: 0
        };

        this.calculateImageSize();
        this.form.facebook = option.facebook;
        this.keyWords = option.seoKeywords
          ? option.seoKeywords.split(",")
          : null;
      }
    });
  }
};
</script>

<style scoped>
.magazine-img {
  border-radius: 5px;
  height: 120px;
  width: 120px;
  object-fit: contain;
  background-color: white !important;
  cursor: pointer;
}
.padding {
  padding: 3rem !important;
}

.user-card-full {
  overflow: hidden;
}

.card {
  border-radius: 5px;
  -webkit-box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
  box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
  border: none;
  margin-bottom: 30px;
}
.m-r-0 {
  margin-right: 0px;
}

.m-l-0 {
  margin-left: 0px;
}

.user-card-full .user-profile {
  border-radius: 5px 0 0 5px;
}

.bg-c-lite-green {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#f29263),
    to(#ee5a6f)
  ) !important;
  background: linear-gradient(to right, #ee5a6f, #f29263) !important;
}

.user-profile {
  padding: 20px 0;
}

.card-block {
  padding: 1.25rem;
}

.m-b-25 {
  margin-bottom: 25px;
}

.img-radius {
  border-radius: 5px;
  height: 120px;
  width: 120px;
  object-fit: contain;
  background-color: white !important;
  cursor: pointer;
}

h6 {
  font-size: 14px;
}

.card .card-block p {
  line-height: 25px;
}

.card-block {
  padding: 1.25rem;
}

.b-b-default {
  border-bottom: 1px solid #e0e0e0;
}

.m-b-20 {
  margin-bottom: 20px;
}

.p-b-5 {
  padding-bottom: 5px !important;
}

.card .card-block p {
  line-height: 25px;
}

.m-b-10 {
  margin-bottom: 10px;
}

.text-muted {
  color: #919aa3 !important;
}

.b-b-default {
  border-bottom: 1px solid #e0e0e0;
}

.f-w-600 {
  font-weight: 600;
}

.m-b-20 {
  margin-bottom: 20px;
}

.m-t-40 {
  margin-top: 20px;
}

.p-b-5 {
  padding-bottom: 5px !important;
}

.m-b-10 {
  margin-bottom: 10px;
}

.m-t-40 {
  margin-top: 20px;
}

.user-card-full .social-link li {
  display: inline-block;
}

.user-card-full .social-link li a {
  font-size: 20px;
  margin: 0 10px 0 0;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
</style>